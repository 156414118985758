import {
	Button,
	Card,
	CardBody,
	CardPlaceholder, Icon, Icons,
	PageLayout,
	StringHelpers, useAuthorisationContext, useLangContext, checkTheme, Themes,
	Permissions
} from 'carrier-fe';
import { useEffect, useState } from 'react';

function Dashboard() {
	const { hasPermissionAccess } = useAuthorisationContext();

	const { getLangPage } = useLangContext();
	const [lang, setLang] = useState<any>(null);

	useEffect(() => {
		if (lang === null) {
			load();
		}
	}, []);

	const load = async () => {
		getLangPage('admin/dashboard')
			.then((res: any) => {
				if (typeof res === "undefined" || res === null) {
					setLang([]);
				} else {
					setLang(res);

					document.title = StringHelpers.title(res?.page.title ?? 'Dashboard');
				}
			})
			.catch((err: any) => console.error(err));
	};

	const getTileById = (id: string) => {
		if (!lang || !lang.page || !lang.page.tiles) return null;

		for (let i = 0; i < lang.page.tiles.length; i++) {
			if (lang.page.tiles[i]['id'] === id) {
				return lang.page.tiles[i];
			}
		}

		return null;
	}

	const renderCompaniesTile = () => {
		if (!hasPermissionAccess(Permissions.ADMIN_COMPANY_INDEX)) return null;

		let content = getTileById('admin.company.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">{!!content && !!content.title ? content.title : ''}</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description ? content.description : ''}
					</p>
					<Button
						label={!!content && !!content.button ? content.button : 'Go to'}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	}

	const renderUsersTile = () => {
		if (!hasPermissionAccess(Permissions.ADMIN_USER_INDEX)) return null;

		let content = getTileById('admin.user.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">{!!content && !!content.title ? content.title : ''}</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description ? content.description : ''}
					</p>
					<Button
						label={!!content && !!content.button ? content.button : 'Go to'}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	}

	const renderTrainingCoursesTile = () => {
		if (!hasPermissionAccess(Permissions.ADMIN_TRAINING_COURSE_INDEX)) return null;

		let content = getTileById('admin.training.course.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">{!!content && !!content.title ? content.title : ''}</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description ? content.description : ''}
					</p>
					<Button
						label={!!content && !!content.button ? content.button : 'Go to'}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	}

	const renderTrainingEventsTile = () => {
		if (!hasPermissionAccess(Permissions.ADMIN_TRAINING_EVENT_INDEX)) return null;

		let content = getTileById('admin.training.event.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">{!!content && !!content.title ? content.title : ''}</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description ? content.description : ''}
					</p>
					<Button
						label={!!content && !!content.button ? content.button : 'Go to'}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	}

	const renderInstallationTile = () => {
		if (!checkTheme(Themes.Toshiba) && !hasPermissionAccess(Permissions.TOSHIBA_ADMIN_REGISTRATION_INDEX)) return null;

		let content = getTileById('admin.installation.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">{!!content && !!content.title ? content.title : ''}</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description ? content.description : ''}
					</p>
					<Button
						label={!!content && !!content.button ? content.button : 'Go to'}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	}

	const renderWarrantyTile = () => {
		if ((!checkTheme(Themes.Toshiba) || !hasPermissionAccess(Permissions.TOSHIBA_ADMIN_WARRANTY_INDEX)) && (!checkTheme(Themes.Viessmann) || !hasPermissionAccess(Permissions.VIESSMANN_ADMIN_WARRANTY_INDEX))) return null;

		let content = getTileById('admin.warranty.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">{!!content && !!content.title ? content.title : ''}</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description ? content.description : ''}
					</p>
					<Button
						label={!!content && !!content.button ? content.button : 'Go to'}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	}

	const renderClaimsTile = () => {
		if (!checkTheme(Themes.Toshiba) || !hasPermissionAccess(Permissions.TOSHIBA_ADMIN_CLAIM_INDEX)) return null;

		let content = getTileById('admin.claim.index');

		return (
			<Card noMargin>
				<CardBody>
					<Icon
						icon={Icons.DASHBOARD}
						style={{ color: '#1E82E6' }}
					/>
					<h4 className="card-title">{!!content && !!content.title ? content.title : ''}</h4>
					<p className="card-text mb-4">
						{!!content && !!content.description ? content.description : ''}
					</p>
					<Button
						label={!!content && !!content.button ? content.button : 'Go to'}
						onClick={() => alert('Not Implemented')}
						className="d-inline-flex"
					/>
				</CardBody>
			</Card>
		);
	}

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title ?? 'Dashboard')}
		>
			<div className="dashboard--inner--wrapper d-grid gap-4">
				{lang === null ? (
					<>
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
						<CardPlaceholder />
					</>
				) : (
					<>
						{renderCompaniesTile()}
						{renderUsersTile()}
						{renderTrainingCoursesTile()}
						{renderTrainingEventsTile()}
						{renderInstallationTile()}
						{renderWarrantyTile()}
						{renderClaimsTile()}
					</>
				)}
			</div>
		</PageLayout>
	);
}

export default Dashboard;
