import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	Icon,
	Icons,
	ImageType,
	PageLayout,
	route,
	StringHelpers,
	useAuthorisationContext,
	useLangContext,
	replaceKeyWithValue,
} from 'carrier-fe';
import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { QuizType } from '../../types/quiz';

function QuizList() {
	const [refreshOnChange] = useState(new Date().toISOString());
	const navigate = useNavigate();
	const [lang, setLang] = useState<any>(null);
	const { crud, fields, models } = useLangContext();
	const { hasPolicyAccess } = useAuthorisationContext();

	const handleOptionSelect = (action: any, data: QuizType) => {
		const { id = '' } = data;

		const actions: { [key: string]: () => void } = {
			view: () => navigate(`/training/quiz/${id}`),
		};

		if (typeof action === 'string' && actions[action]) {
			actions[action]();
		}
	};

	const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.title || ':model List ',
					'model',
					crud?.models?.training_quiz || 'Training Quiz'
				)
			),
		[lang]
	);

	const items = useMemo(() => {
		if (!hasPolicyAccess({ name: 'training_quiz', method: 'create' }))
			return [];

		return [
			{
				label: StringHelpers.title(
					crud?.buttons?.create?.default || 'Create'
				),
				disabled: false,
				onClick: () => navigate('/training/quiz/create'),
				icon: <Icon icon={Icons.ADD} />,
			},
		];
	}, []);

	const colMapping = (data: QuizType) => [
		{
			id: 'name',
			label: StringHelpers.title(fields?.name || 'Name'),
			value: StringHelpers.title(data?.name || '-'),
			sortable: true,
		},
		{
			id: 'modules_count',
			label: StringHelpers.title(fields?.modules || 'Modules'),
			value: String(data?.modules_count) || '-',
			sortable: false,
		},
		{
			id: 'questions_count',
			label: StringHelpers.title(fields?.questions || 'Questions'),
			value: data?.questions_count || '-',
			sortable: true,
		},
		{
			id: 'created_at',
			label: StringHelpers.title(fields?.created_at || 'Created'),
			value: formatDate(data.created_at) || '-',
			sortable: true,
		},
		{
			id: 'actions',
			label: StringHelpers.title(fields?.actions || 'Actions'),
			value: data.permissions.view ? (
				<FormInput
					type="select"
					label={StringHelpers.title(fields?.action || 'Action')}
					placeholder={StringHelpers.title(
						fields?.select || 'Select'
					)}
					name="actions"
					value=""
					onChange={(value) => {
						if (!!value && typeof value === 'string') {
							handleOptionSelect(value, data);
						}
					}}
					options={[
						{
							label: StringHelpers.title(crud?.view || 'View'),
							value: 'view',
						},
					]}
					noMargin
					disabled={!data.permissions.view}
				/>
			) : null,
		},
	];

	return (
		<>
			<PageLayout
				title={title || 'Training Quizzes'}
				lang={{
					page: 'admin/generic',
					setLang: setLang,
				}}
			>
				<DataTable<QuizType>
					routeEndpoint={route(
						API_ENDPOINTS.ADMIN.TRAINING.QUIZ.INDEX
					)}
					colMapping={colMapping}
					items={items}
					incrementPaginationButtons
					refreshOnChange={refreshOnChange}
					canViewArchived
				/>
			</PageLayout>
		</>
	);
}

export default QuizList;
