import axios from 'axios';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modules from './components/Modules';

type ModuleType = {
	id?: string;
	[key: string]: any;
	created_at?: string;
	updated_at?: string;
	deleted_at?: string;
};

const defaultData = {
	name: '',
	description: '',
	training_modules: [
		{
			name: '',
			description: '',
			type: '',
			order: 0,
		},
	] as ModuleType[],
};

type Data = typeof defaultData;

function StageForm() {
	const { courseId, courseStageId } = useParams();
	const isEdit = courseStageId ? true : false;

	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		if (isEdit) {
			fetchStageData();
		}
	}, []);

	const fetchStageData = async () => {
		if (isFetching) return;

		setIsFetching(true);

		try {
			const { data } = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.STAGE.EDIT, {
					trainingCourse: String(courseId),
					trainingCourseStage: String(courseStageId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
		}
	};

	const updateStage = async (payload: typeof defaultData) => {
		return isEdit
			? axios.patch<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.STAGE.UPDATE, {
						trainingCourse: String(courseId),
						trainingCourseStage: String(courseStageId),
					}),
					payload
			  )
			: axios.post<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.STAGE.STORE, {
						trainingCourse: String(courseId),
					}),
					payload
			  );
	};

	const { Input, Submit, errors, store, setValues, isSuccess } = useForm(
		updateStage,
		defaultData
	);

	useEffect(() => {
		if (isSuccess) {
			navigate(`/training/course/${courseId}`);
		}
	}, [isSuccess]);

	let title = replaceKeyWithValue(
		crud?.pages?.create.title || 'Create :model',
		'model',
		crud?.models?.training_course_stage || 'Training Course Stage'
	);

	if (isEdit) {
		title = replaceKeyWithValue(
			crud?.pages?.edit.title || 'Edit :model',
			'model',
			crud?.models?.training_course_stage || 'Training Course Stage'
		);
	}

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<Card>
					<CardBody className={'pb-0'}>
						<SubTitle
							title={StringHelpers.title(
								crud?.sub_titles?.details || 'Details'
							)}
							className="mb-3 w-100"
							style={{ color: '#464C5E' }}
						/>
						{Input({
							name: 'name',
							label: StringHelpers.title(fields?.name || 'Name'),
						})}
						{Input({
							name: 'description',
							type: 'textarea',
							label: StringHelpers.title(
								fields?.description || 'Description'
							),
						})}
					</CardBody>
				</Card>
				<Modules
					store={store}
					setValues={setValues}
					errors={errors}
				/>
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={crud?.buttons.back.default || 'Back'}
					onClick={() => navigate(`/training/course/${courseId}`)}
					variant={Variants.Dark}
					type={ButtonTypes.Outline}
					className={'me-4'}
					disabled={disabled}
				/>
				<Submit
					defaultLabel={
						isEdit
							? crud?.buttons.edit.default || 'Update'
							: crud?.buttons.create.default || 'Create'
					}
					loadingLabel={
						isEdit
							? crud?.buttons.edit.submitting || 'Updating...'
							: crud?.buttons.create.submitting || 'Creating...'
					}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default StageForm;
