import { DndContext } from '@dnd-kit/core';
import {
	arrayMove,
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { ModuleType, StageType } from '../../../../../types/course';
import { useStagesContext } from '../StagesContext';
import Module from '../Module';

type Props = {
	initialItems: ModuleType[];
};

function SortableModules({ initialItems }: Props) {
	// Context for managing stages globally
	const { items, setItems } = useStagesContext();

	// Local state for managing modules and their parent stage
	const [modules, setModules] = useState<ModuleType[]>(initialItems);
	const [parent, setParent] = useState<StageType | undefined>();

	// Handle drag-and-drop events
	const handleDragEnd = (event: any) => {
		const { active, over } = event;

		// If dropped outside of a valid target, do nothing
		if (!over) return;

		// Only process if the item was moved
		if (active.id !== over.id) {
			// Find the parent stage of the active module
			const updatedParent = items.find((stage) =>
				stage.training_modules.some((module) => module.id === active.id)
			);
			setParent(updatedParent);

			// Reorder modules locally
			setModules((currentModules) => {
				const oldIndex = currentModules.findIndex(
					(module) => module.id === active.id
				);
				const newIndex = currentModules.findIndex(
					(module) => module.id === over.id
				);

				// Reorder the items using arrayMove
				const reorderedItems = arrayMove(
					currentModules,
					oldIndex,
					newIndex
				);

				// Update the 'order' key for each item
				return reorderedItems.map((item, index) => ({
					...item,
					order: index, // Assign new index as the order
				}));
			});
		}
	};

	// Update the global state whenever the modules or their parent changes
	useEffect(() => {
		if (parent) {
			const updatedStages = items.map((stage) =>
				stage.id === parent.id
					? { ...stage, training_modules: modules }
					: stage
			);
			setItems(updatedStages);
		}
	}, [modules]);

	return (
		<DndContext onDragEnd={handleDragEnd}>
			<SortableContext
				items={modules}
				strategy={verticalListSortingStrategy}
			>
				{modules.map((module) => (
					<Module
						key={module.id}
						id={module.id as string}
						item={module}
					/>
				))}
			</SortableContext>
		</DndContext>
	);
}

export default SortableModules;
