import { useEffect, useState } from 'react';
import { CourseType, StageType } from '../../../../../types/course';
import {
	API_ENDPOINTS,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	Dropdown,
	Icon,
	Icons,
	Modal,
	replaceKeyWithValue,
	route,
	Sizes,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useLangContext,
	Variants,
} from 'carrier-fe';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';
import SortableStages from '../SortableStages';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

type Props = {
	store: CourseType;
	fetchCourseData: () => void;
};

function Stages({ store, fetchCourseData }: Props) {
	const { courseId } = useParams();

	const [isSortable, setIsSortable] = useState<boolean>(false);
	const { crud, fields } = useLangContext();
	const { disabled, setDisabled } = useDisabledContext();

	const navigate = useNavigate();

	const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
	const [archiving, setArchiving] = useState<boolean>(false);

	const [itemToDelete, setItemToDelete] = useState<StageType | null>(null);

	useEffect(() => {
		if (archiving) {
			archive();
		}
	}, [archiving]);

	const archive = async () => {
		setDisabled(true);

		try {
			let response = await axios.delete(
				route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.STAGE.DELETE, {
					trainingCourse: String(courseId),
					trainingCourseStage: itemToDelete?.id as string,
				})
			);
			customToast({
				title: response?.data?.message || 'Success.',
				variant: Variants.Success,
			});
			fetchCourseData();
		} catch (error: any) {
			customToast({
				// @ts-ignore
				title:
					error?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setItemToDelete(null);
			setDisabled(false);
			setArchiving(false);
			setArchiveModalOpen(false);
		}
	};

	const getActions = (): DropdownItemProps[] => {
		if (!store || !store.permissions) return [];

		let actions: DropdownItemProps[] = [];

		if (store?.permissions.update) {
			actions.push({
				label: crud?.buttons.create.default,
				onClick: () => navigate(`/training/course/${courseId}/stage`),
				variant: Variants.Dark,
				icon: Icons.ADD_CIRCLE,
			});
		}

		if (store?.permissions.update && store?.training_stages?.length > 0) {
			actions.push({
				label: 'Reorder',
				onClick: () => setIsSortable(true),
				variant: Variants.Dark,
				icon: Icons.REORDER,
			});
		}

		return actions;
	};

	let actions = getActions();

	const getStageActions = (stage: StageType): DropdownItemProps[] => {
		if (!stage || !stage.permissions) return [];

		let stageActions: DropdownItemProps[] = [];

		if (stage?.permissions.update) {
			stageActions.push({
				label: StringHelpers.title(
					crud?.buttons.edit.default || 'Update'
				),
				icon: Icons.EDIT,
				onClick: () =>
					navigate(
						`/training/course/${courseId}/stage/${stage.id}/edit`
					),
			});
		}

		if (stage?.permissions.delete) {
			stageActions.push({
				label: StringHelpers.title(
					crud?.buttons.archive.default || 'Archive'
				),
				onClick: () => {
					setItemToDelete(stage);
					setArchiveModalOpen(true);
				},
				variant: Variants.Danger,
				icon: Icons.DELETE,
			});
		}

		return stageActions;
	};

	return (
		<>
			<div className="mb-4 d-flex justify-content-between align-items-center">
				<SubTitle
					title={StringHelpers.title(
						crud?.sub_titles?.stages_modules || 'Stages & Modules'
					)}
					style={{ color: '#464C5E' }}
				/>
				{actions.length > 0 && !store.deleted_at && (
					<Dropdown
						label={StringHelpers.title(
							crud?.buttons?.actions?.default || 'Actions'
						)}
						items={getActions()}
						type={ButtonTypes.Solid}
						variant={Variants.Dark}
					/>
				)}
			</div>

			{store.training_stages && store.training_stages.length > 0 ? (
				isSortable ? (
					<SortableStages
						initialItems={store.training_stages}
						fetchCourseData={fetchCourseData}
						setIsSortable={setIsSortable}
					/>
				) : (
					store.training_stages.map((stage) => (
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between">
									<div>
										<h6
											className="text-uppercase fw-bold"
											style={{
												color: '#1E82E6',
												fontSize: '14px',
											}}
										>
											Stage {stage.order + 1}
										</h6>
										<h4 className="mb-1">{stage.name}</h4>
										{stage.description && (
											<div
												style={{
													color: '#667085',
												}}
											>
												{StringHelpers.limit(
													stage.description,
													200
												)}
											</div>
										)}
									</div>
									{!store.deleted_at && (
										<Dropdown
											label={StringHelpers.title(
												crud?.buttons?.actions
													?.default || 'Actions'
											)}
											items={getStageActions(stage)}
											type={ButtonTypes.Outline}
											variant={Variants.Dark}
											size={Sizes.Small}
										/>
									)}
								</div>
								<div className="mt-4">
									{stage?.training_modules?.map((module) => (
										<Card className="mb-2">
											<CardBody className="d-flex align-items-center justify-content-between p-2">
												<div className="d-flex align-items-center">
													<div
														className="d-inline-flex align-items-center justify-content-center p-2 rounded-2"
														style={{
															width: '40px',
															height: '40px',
															backgroundColor:
																'#E9EFFB',
															color: '#1E82E6',
														}}
													>
														{module.type ===
														'quiz' ? (
															<Icon
																icon={
																	Icons.QUIZ
																}
															/>
														) : module.type ===
														  'pdf' ? (
															<Icon
																icon={
																	Icons.DESCRIPTION
																}
															/>
														) : (
															<Icon
																icon={
																	Icons.PLAY_CIRCLE
																}
															/>
														)}
													</div>
													<div className="ms-3">
														<h6 className="mb-1">
															{StringHelpers.title(
																StringHelpers.limit(
																	module.name,
																	100
																)
															)}
														</h6>
														{module.description && (
															<div
																className="mb-1"
																style={{
																	color: '#667085',
																}}
															>
																{StringHelpers.limit(
																	module.description,
																	200
																)}
															</div>
														)}
														<div
															style={{
																color: '#667085',
															}}
														>
															{module.type}
														</div>
													</div>
												</div>
												{module.type === 'quiz' ? (
													<a
														className="btn p-2 d-inline-flex align-items-center justify-content-center"
														href={`/training/quiz/${module.training_quiz_id}`}
														target="_blank"
														style={{
															color: '#667085',
														}}
													>
														<Icon
															icon={
																Icons.OPEN_IN_NEW
															}
														/>
													</a>
												) : module.type === 'youtube' ||
												  (module.type ===
														'slideshow' &&
														module.file ===
															null) ? (
													<a
														className="btn p-2 d-inline-flex align-items-center justify-content-center"
														href={
															module?.external_url
														}
														target="_blank"
														style={{
															color: '#667085',
														}}
													>
														<Icon
															icon={
																Icons.OPEN_IN_NEW
															}
														/>
													</a>
												) : (
													<a
														className="btn p-2 d-inline-flex align-items-center justify-content-center"
														href={module?.file?.url}
														target="_blank"
														style={{
															color: '#667085',
														}}
													>
														<Icon
															icon={
																Icons.DOWNLOAD
															}
														/>
													</a>
												)}
											</CardBody>
										</Card>
									))}
								</div>
							</CardBody>
						</Card>
					))
				)
			) : (
				<Card>
					<CardBody>
						<p className="mb-0">
							{fields.add_stages_modules ||
								'There are currently no stages & modules for this course. Please add them using the controls.'}
						</p>
					</CardBody>
				</Card>
			)}

			<Modal
				open={archiveModalOpen}
				onClose={() => {
					setArchiveModalOpen(false);
				}}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.archive.title || 'Archive :name?',
						'name',
						crud?.models?.training_course_stage ||
							'Training Course Stage'
					)
				)}
				confirmText={
					archiving
						? crud?.buttons.archive.submitting || 'Archiving...'
						: crud?.buttons.archive.default || 'Archive'
				}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => setArchiving(true)}
				disabled={disabled || archiving}
				variant={Variants.Danger}
			>
				{!!store && (
					<>
						{crud?.modals?.archive.description.map(
							(item: string, key: number) => {
								return (
									<p key={key}>
										{replaceKeyWithValue(
											item,
											'name',
											itemToDelete?.name as string
										)}
									</p>
								);
							}
						)}
						{itemToDelete?.description && (
							<p>
								{`${StringHelpers.title(
									fields?.description || 'Description'
								)}: ${itemToDelete.description}`}
							</p>
						)}
						<p>
							{`${
								fields?.number_of_modules || 'Number of modules'
							}: ${itemToDelete?.training_modules.length || 0}`}
						</p>
					</>
				)}
			</Modal>
		</>
	);
}

export default Stages;
