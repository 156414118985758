import axios from 'axios';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	FormInput,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	WidthConstrainedContainer,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AttendeeType } from '../../../types/event';

const defaultData = {
	attendees: [] as AttendeeType[],
	confirm_attendance: '0',
};

type Data = typeof defaultData;

function EventComplete() {
	const { eventId } = useParams();

	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	const [isFetching, setIsFetching] = useState(false);

	useEffect(() => {
		fetchEventData();
	}, []);

	const fetchEventData = async () => {
		if (isFetching) return;
		setIsFetching(true);

		try {
			const { data } = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.COMPLETE, {
					trainingEvent: String(eventId),
				})
			);
			setValues(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
		}
	};

	const completeEvent = async (payload: typeof defaultData) => {
		return axios.patch<{ data: Data; message: string }>(
			route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.COMPLETE, {
				trainingEvent: String(eventId),
			}),
			payload
		);
	};

	const { Input, Submit, errors, store, setValues, isSuccess } = useForm(
		completeEvent,
		defaultData
	);

	useEffect(() => {
		if (isSuccess) {
			navigate(`/training/event/${eventId}`);
		}
	}, [isSuccess]);

	let title = replaceKeyWithValue(
		crud?.pages?.complete?.title || 'Complete :model',
		'model',
		crud?.models?.training_event || 'Training Event'
	);

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				<Card>
					<CardBody className={'pb-0'}>
						<SubTitle
							title={StringHelpers.title(
								fields?.attendees || 'Attendees'
							)}
							className="mb-3"
							style={{ color: '#464C5E' }}
						/>
						<p className="mb-4">
							{fields?.complete_event_description ||
								'Please confirm the attendee list before proceeding. To make updates, return to the previous page. Once you submit this page, the attendee list will no longer be editable.'}
						</p>
						{store.attendees.map(
							(attendee: AttendeeType, index) => (
								<Card key={index}>
									<CardBody>
										<FormInput
											type="text"
											label={StringHelpers.title(
												fields?.name || 'Name'
											)}
											name={`attendees.${index}.name`}
											value={StringHelpers.title(
												(attendee.name as string) || ''
											)}
											onChange={() => {}}
											disabled
										/>
										<FormInput
											type="text"
											label={StringHelpers.title(
												fields?.email || 'Email'
											)}
											name={`attendees.${index}.email`}
											value={attendee.email as string}
											onChange={() => {}}
											disabled
										/>
										<FormInput
											type="select"
											label={StringHelpers.title(
												fields?.passed || 'Passed'
											)}
											placeholder={StringHelpers.title(
												fields?.select || 'Select'
											)}
											name={`attendees.${index}.passed`}
											value={attendee.passed}
											onChange={(value: any) => {
												setValues({
													attendees:
														store.attendees.map(
															(a) =>
																a.name ===
																attendee.name
																	? {
																			...a,
																			passed: value,
																	  }
																	: a
														),
												});
											}}
											options={
												crud?.options?.boolean || []
											}
											errorMessages={
												errors?.[
													`attendees.${index}.passed`
												] || null
											}
											noMargin
										/>
									</CardBody>
								</Card>
							)
						)}
						{Input({
							name: 'confirm_attendance',
							label:
								fields?.confirm_attendance ||
								'Confirm attendence',
							placeholder: fields?.select || 'Select',
							type: 'select',
							options: crud?.options?.boolean || [],
						})}
					</CardBody>
				</Card>
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={crud?.buttons.back.default || 'Back'}
					onClick={() => navigate(`/training/event/${eventId}`)}
					variant={Variants.Dark}
					type={ButtonTypes.Outline}
					className={'me-4'}
					disabled={disabled}
				/>
				<Submit
					defaultLabel={crud?.buttons.complete.default || 'Complete'}
					loadingLabel={
						crud?.buttons.complete.submitting || 'Completing...'
					}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default EventComplete;
