import { useEffect, useState } from 'react';
import { DndContext } from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
	arrayMove,
} from '@dnd-kit/sortable';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	customToast,
	route,
	useDisabledContext,
	useLangContext,
	Variants,
} from 'carrier-fe';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { StagesContext } from '../StagesContext';
import { StageType } from '../../../../../types/course';
import Stage from '../Stage';

type Props = {
	initialItems: StageType[];
	fetchCourseData: () => void;
	setIsSortable: (value: boolean) => void;
};

function SortableStages({
	initialItems,
	fetchCourseData,
	setIsSortable,
}: Props) {
	const { courseId } = useParams();

	const [items, setItems] = useState<StageType[]>(initialItems);
	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();

	const [submitting, setSubmitting] = useState<boolean>(false);

	useEffect(() => {
		if (submitting) {
			updateOrder();
		}
	}, [submitting]);

	const updateOrder = async () => {
		try {
			const { data } = await axios.patch(
				route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.ORDER, {
					trainingCourse: String(courseId),
				}),
				{ training_stages: items }
			);
			customToast({
				title: data.message,
				variant: Variants.Success,
			});
			fetchCourseData();
			setIsSortable(false);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		}
	};

	const handleDragEnd = (event: any) => {
		const { active, over } = event;

		if (active.id !== over.id) {
			setItems((items) => {
				const oldIndex = items.findIndex(
					(item) => item.id === active.id
				);
				const newIndex = items.findIndex((item) => item.id === over.id);

				// Reorder the items using arrayMove
				const reorderedItems = arrayMove(items, oldIndex, newIndex);

				// Update the 'order' key for each item
				return reorderedItems.map((item, index) => ({
					...item,
					order: index, // Assign new index as the order
				}));
			});
		}
	};

	const handleCancel = () => {
		setIsSortable(false);
		customToast({
			title:
				fields?.cancel_reorder ||
				'Success. Reorder for stages has been cancelled.',
			variant: Variants.Success,
		});
	};

	return (
		<StagesContext.Provider
			value={{
				items: items,
				setItems: (value) => setItems(value),
			}}
		>
			<DndContext onDragEnd={handleDragEnd}>
				<SortableContext
					items={items}
					strategy={verticalListSortingStrategy}
				>
					{items.map((item) => (
						<Stage
							key={item.id}
							id={item.id}
							item={item}
							nestedItems={item.training_modules}
						/>
					))}
				</SortableContext>
			</DndContext>
			<BottomAnchoredControls>
				<Button
					label={crud?.buttons.cancel.default || 'Cancel'}
					onClick={handleCancel}
					variant={Variants.Dark}
					type={ButtonTypes.Outline}
					className="me-4"
					disabled={disabled}
				/>
				<Button
					label={
						submitting
							? crud?.buttons.edit.submitting || 'Updating...'
							: crud?.buttons.edit.default || 'Update'
					}
					onClick={() => setSubmitting(true)}
					disabled={disabled}
				/>
			</BottomAnchoredControls>
		</StagesContext.Provider>
	);
}

export default SortableStages;
