import axios from 'axios';
import {
  API_ENDPOINTS,
  BottomAnchoredControls,
  Button,
  ButtonTypes,
  Card,
  CardBody,
  customToast,
  PageLayout,
  replaceKeyWithValue,
  route,
  StringHelpers,
  SubTitle,
  useDisabledContext,
  useForm,
  useLangContext,
  Variants,
  WidthConstrainedContainer,
  PostcodeLookup,
  useAuthorisationContext
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UserViewType } from '../../../types/user';

const defaultData = {
  first_name: '',
  last_name: '',
  email: '',
  username: '',
  mobile_phone_prefix: '+44',
  mobile_phone_number: '',
  landline_phone_prefix: '+44',
  landline_phone_number: '',
  address_id: '',
  local_authority_code: '',
  address_line_1: '',
  address_line_2: '',
  address_line_3: '',
  town_city: '',
  state_county: '',
  postcode_zipcode: '',
  country: '',
  sub_country_name: '',
  company_id: '',
  role_id: '',
  enable_system_access: '0',
  gas_safe_license_card_number: '',
  gas_safe_confirmation: '',
  username_update: '1'
};

type Data = typeof defaultData;

function UserForm() {
  const { userId } = useParams();
  const isEdit = userId ? true : false;

  const { crud, fields } = useLangContext();
  const { disabled } = useDisabledContext();
  const navigate = useNavigate();

  const [backupEmail, setBackupEmail] = useState("")
  const [isFetching, setIsFetching] = useState(false);
  const [roles, setRoles] = useState<any[]>([]);
  const [companies, setCompanies] = useState<{ label: string; value: string }[]>([])
  const { hasRoleAccess } = useAuthorisationContext();
  const [searchParams] = useSearchParams();

  const isSuperAdminOrDistributionManagerOrPlatformManager = hasRoleAccess('super_admin') || hasRoleAccess('platform_manager') || hasRoleAccess('distribution_manager');

  const fetchCourseData = async () => {
    if (isFetching) return;
    setIsFetching(true);

    try {
      const { data } = await axios.get<{ data: UserViewType }>(
        route(API_ENDPOINTS.ADMIN.USER.VIEW, {
          userId: String(userId),
        })
      );

      const flatternObject: Record<string, unknown> = {
        ...data.data,
        ...data.data.individual,
        ...data.data.individual.address
      }

      const val: Record<string, unknown> = {}

      Object.keys(defaultData).forEach(key => {
        val[key] = flatternObject[key];
      })

      if (flatternObject.company) {
        val['company_id'] = (flatternObject as any).individual.company.id || '';
      }
      val['username_update'] = "0"
      setValues(val);
      setBackupEmail(val.email as string);
    } catch (err: any) {
      customToast({
        title:
          err?.response?.data?.message ||
          'An error occurred. Please try again.',
        variant: Variants.Danger,
      });
    } finally {
      setIsFetching(false);
    }
  };

  const fetchRoles = async () => {
    if (isFetching) return;
    setIsFetching(true);

    try {
      const { data } = await axios.get<{ data: any[] }>(
        route(API_ENDPOINTS.GENERIC.SELECT.COMPANY_ROLES, { company: store.company_id }));
      setRoles(data.data);
    } catch (err: any) {
      customToast({
        title:
          err?.response?.data?.message ||
          'An error occurred. Please try again.',
        variant: Variants.Danger,
      });
    } finally {
      setIsFetching(false);
    }
  }

  const fetchCompanies = async () => {
    if (isFetching) return;
    setIsFetching(true);

    try {
      const { data } = await axios.get<{ data: { label: string, value: string }[] }>(
        route(API_ENDPOINTS.GENERIC.SELECT.COMPANIES) + '?only_approved=1'
      );
      setCompanies(data.data)
    } catch (err: any) {
      customToast({
        title:
          err?.response?.data?.message ||
          'An error occurred. Please try again.',
        variant: Variants.Danger,
      });
    } finally {
      setIsFetching(false);
    }
  }

  const updateCourse = async (payload: typeof defaultData) => {
    return isEdit
      ? axios.patch<{ data: Data; message: string }>(
        route(API_ENDPOINTS.ADMIN.USER.UPDATE, {
          userId: String(userId),
        }),
        payload
      )
      : axios.post<{ data: Data; message: string }>(
        route(API_ENDPOINTS.ADMIN.USER.STORE),
        payload
      );
  };

  const { Input, Submit, setValues, data, errors, store } = useForm(
    updateCourse,
    defaultData
  );

  useEffect(() => {
    if (isEdit) {
      fetchCourseData();
    }
    if (isSuperAdminOrDistributionManagerOrPlatformManager) {
      fetchCompanies();
    }
  }, []);

  useEffect(() => {
    if (data) {
      navigate(`/user/${data.id}`);
    }
  }, [data]);

  useEffect(() => {
    if (isSuperAdminOrDistributionManagerOrPlatformManager) {
      fetchRoles();
    }
  }, [store.company_id])

  useEffect(() => {
    if (store.username_update === "1")
      setValues({ username: store.email });
  }, [store.email])

  useEffect(() => {
    if (store.username_update === "0")
      setValues({ username: backupEmail });
    else
      setValues({ username: store.email });
  }, [store.username_update])

  useEffect(() => {
    if (!isEdit && roles.length > 0 && store.role_id === '') {
      setValues({
        ...store,
        role_id: roles[0]?.value || ''
      })
    }
  }, [roles])

  useEffect(() => {
    const queryCompanyId = searchParams.get('companyId');
    if (queryCompanyId && companies.map(c => c.value).includes(queryCompanyId)) {
      setValues({ company_id: queryCompanyId })
    }
  }, [searchParams, companies])

  let title = replaceKeyWithValue(
    crud?.pages?.create.title || 'Create :model',
    'model',
    crud?.models?.user || 'User'
  );

  if (isEdit) {
    title = replaceKeyWithValue(
      crud?.pages?.edit.title || 'Edit :model',
      'model',
      crud?.models?.user || 'User'
    );
  }

  return (
    <PageLayout title={StringHelpers.title(title)}>
      <WidthConstrainedContainer>
        <Card>
          <CardBody>
            <SubTitle
              title={StringHelpers.title(
                crud?.sub_titles?.details || 'Details'
              )}
              className="mb-3"
              style={{ color: '#464C5E' }}
            />
            {Input({
              name: 'first_name',
              label: fields?.first_name || 'First Name',
            })}
            {Input({
              name: 'last_name',
              label: fields?.last_name || 'Last Name',
            })}
            {Input({
              name: 'email',
              label: fields?.email || 'Email',
            })}
            {Input({
              name: 'username',
              label: fields?.username || 'Username',
              disable: true
            })}
            {isEdit && store.email !== backupEmail && <div className='d-flex gap-2 w-100 align-items-center'>
              <p className='w-100'>{fields.update_username_to_match_email || 'Would you like to update the username to match the email?'}</p>
              <div className='w-25'>
                {Input({
                  name: 'username_update',
                  label: 'Update username',
                  type: 'select',
                  options: crud.options.boolean
                })}
              </div>
            </div>}
            {Input({
              name: 'company_id',
              label: fields?.company || 'Company',
              type: "searchable-select",
              options: companies,
              disable: !isSuperAdminOrDistributionManagerOrPlatformManager || companies.length <= 1

            })}
            {Input({
              name: 'role_id',
              label: fields?.role || 'Role',
              type: 'select',
              options: roles,
              disable: !isSuperAdminOrDistributionManagerOrPlatformManager || roles.length <= 1
            })}
            {Input({
              name: 'mobile_phone_number',
              label: fields?.mobile_phone_number || 'Mobile Number',
              type: "tel"
            })}
            {Input({
              name: 'landline_phone_number',
              label: fields?.landline_phone_number || 'Landline Number',
              type: "tel"
            })}
            {Input({
              name: 'enable_system_access',
              label: fields?.enable_system_access || 'Enable System Access',
              type: "select",
              options: crud?.options?.boolean
            })}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <PostcodeLookup
              data={store}
              setData={setValues}
              errors={errors}
            />
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <SubTitle
              title={StringHelpers.title(
                crud?.sub_titles?.gas_safe || 'Gas Safe'
              )}
              className="mb-3"
              style={{ color: '#464C5E' }}
            />
            {Input({
              name: 'gas_safe_license_card_number',
              label: fields?.gas_safe_license_card_number || 'Gas Safe License Card Number',
            })}
            {Input({
              name: 'gas_safe_confirmation',
              label: fields?.gas_safe_confirmation || 'Gas Safe Confirmation',
              type: "checkbox"
            })}
          </CardBody>
        </Card>
      </WidthConstrainedContainer>
      <BottomAnchoredControls>
        <Button
          label={crud?.buttons.back.default || 'Back'}
          onClick={() =>
            isEdit
              ? navigate(`/user/${userId}`)
              : navigate('/user')
          }
          variant={Variants.Dark}
          type={ButtonTypes.Outline}
          className={'me-4'}
          disabled={disabled}
        />
        <Submit
          defaultLabel={
            isEdit
              ? crud?.buttons.edit.default || 'Update'
              : crud?.buttons.create.default || 'Create'
          }
          loadingLabel={
            isEdit
              ? crud?.buttons.edit.submitting || 'Updating...'
              : crud?.buttons.create.submitting || 'Creating...'
          }
        />
      </BottomAnchoredControls>
    </PageLayout>
  );
}

export default UserForm;
