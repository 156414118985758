import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card, CardBody, Icon, Icons, StringHelpers } from 'carrier-fe';
import { ModuleType } from '../../../../../types/course';

type Props = {
	id: string | number;
	item: ModuleType;
};

function Module({ id, item }: Props) {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });

	const style = {
		transform: CSS.Translate.toString(transform), // https://github.com/clauderic/dnd-kit/issues/817#issuecomment-1168919686
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		>
			<Card className="mb-2">
				<CardBody className="d-flex p-2">
					<div className="d-flex align-items-center border-end pe-2">
						<Icon icon={Icons.DRAG_HANDLE} />
					</div>
					<div className="flex-fill ms-3">
						<h6 className="mb-0">
							{StringHelpers.title(
								StringHelpers.limit(item.name, 100)
							)}
						</h6>
						{item.description && (
							<div
								style={{
									color: '#667085',
								}}
							>
								{StringHelpers.limit(item.description, 200)}
							</div>
						)}
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default Module;
