import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Card, CardBody, Icon, Icons } from 'carrier-fe';
import SortableModules from '../SortableModules';
import { ModuleType, StageType } from '../../../../../types/course';

type Props = {
	id: string | number;
	item: StageType;
	nestedItems: ModuleType[];
};

function Stage({ id, item, nestedItems }: Props) {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id });

	const style = {
		transform: CSS.Translate.toString(transform), // https://github.com/clauderic/dnd-kit/issues/817#issuecomment-1168919686
		transition,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
		>
			<Card>
				<CardBody className="d-flex">
					<div className="d-flex align-items-center border-end pe-4">
						<Icon icon={Icons.DRAG_INDICATOR} />
					</div>
					<div className="flex-fill ms-4">
						<h6
							className="text-uppercase fw-bold"
							style={{
								color: '#1E82E6',
								fontSize: '14px',
							}}
						>
							Stage {item.order + 1}
						</h6>
						<h4 className="mb-0">{item.name}</h4>
						{nestedItems.length > 0 && (
							<div className="mt-4">
								<SortableModules initialItems={nestedItems} />
							</div>
						)}
					</div>
				</CardBody>
			</Card>
		</div>
	);
}

export default Stage;
