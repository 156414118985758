import {
	API_ENDPOINTS,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	Dropdown,
	FormInput,
	Icons,
	route,
	SelectOption,
	Sizes,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useLangContext,
	Variants,
} from 'carrier-fe';
import { useEffect, useState } from 'react';
import axios from 'axios';

type ModuleType = {
	id?: string;
	[key: string]: any;
	created_at?: string;
	updated_at?: string;
	deleted_at?: string;
};

type Props = {
	store: { training_modules: ModuleType[] };
	setValues: (values: { training_modules: ModuleType[] }) => void;
	errors: any;
};

function Modules({ store, setValues, errors }: Props) {
	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();

	const [types, setTypes] = useState<SelectOption[]>();
	const [order, setOrder] = useState<number>(0);
	const [quizzes, setQuizzes] = useState<SelectOption[]>();

	useEffect(() => {
		fetchTypes();
		fetchQuizzes();
		initializeTrainingModules();
	}, []);

	const fetchQuizzes = async () => {
		try {
			const { data } = await axios.get(
				route(API_ENDPOINTS.GENERIC.SELECT.TRAINING.QUIZZES)
			);
			setQuizzes(data.data);
		} catch (error) {
			console.error('Error fetching quizzes:', error);
		}
	};

	const fetchTypes = async () => {
		try {
			const { data } = await axios.get(
				route(API_ENDPOINTS.GENERIC.SELECT.TRAINING.MODULE_TYPES)
			);
			setTypes(data.data);
		} catch (error) {
			console.error('Error fetching types:', error);
		}
	};

	const initializeTrainingModules = () => {
		setValues({
			training_modules: [
				{ name: '', description: '', type: '', order: 0 },
			],
		});
	};

	const handleChange = (
		index: number,
		field: string,
		value: string | string[]
	) => {
		const updatedModules = [...store.training_modules];

		if (field === 'type' && updatedModules[index][field] !== value) {
			// Clear fields when the type changes
			updatedModules[index] = {
				...updatedModules[index],
				type: value,
				file: undefined,
				external_url: '',
				training_quiz_id: '', // Clear quiz selection if applicable
			};
		} else {
			updatedModules[index][field] = value;
		}

		setValues({ training_modules: updatedModules });
	};

	const removeModule = (index: number) => {
		const updatedModules = store.training_modules.filter(
			(_, i) => i !== index
		);
		setValues({
			training_modules: updatedModules.map((module, idx) => ({
				...module,
				order: idx,
			})),
		});
	};

	const addItem = () => {
		setValues({
			training_modules: [
				...store.training_modules,
				{ name: '', description: '', type: '', order: order + 1 },
			],
		});
	};

	useEffect(() => {
		setOrder(store.training_modules.length - 1);
	}, [store.training_modules]);

	return (
		<Card>
			<CardBody>
				<SubTitle
					title={StringHelpers.title(
						crud?.sub_titles?.modules || 'Modules'
					)}
					className="mb-3 w-100"
					style={{ color: '#464C5E' }}
				/>
				{store.training_modules.map((item, index) => (
					<Card
						key={index}
						className="mb-3"
					>
						<CardBody>
							{store.training_modules.length > 1 && (
								<div className="d-flex justify-content-end mb-4">
									<Dropdown
										label={StringHelpers.title(
											crud?.buttons?.actions?.default ||
												'Actions'
										)}
										items={[
											{
												label:
													crud?.buttons?.delete
														?.default || 'Delete',
												icon: Icons.DELETE,
												onClick: () =>
													removeModule(index),
												variant: Variants.Danger,
											},
										]}
										disabled={disabled}
										type={ButtonTypes.Outline}
										variant={Variants.Dark}
										size={Sizes.Small}
									/>
								</div>
							)}
							<div className="row">
								<div className="col-12 col-lg-8">
									<FormInput
										type="text"
										label={StringHelpers.title(
											fields?.name || 'Name'
										)}
										name="name"
										value={item.name || ''}
										onChange={(value) =>
											handleChange(
												index,
												'name',
												value as string
											)
										}
										disabled={disabled}
										errorMessages={
											errors?.[
												`training_modules.${index}.name`
											] || null
										}
									/>
								</div>
								<div className="col-12 col-lg-4">
									<FormInput
										type="select"
										label={StringHelpers.title(
											fields?.type || 'Type'
										)}
										placeholder={StringHelpers.title(
											fields?.select || 'Select'
										)}
										name="type"
										value={item.type}
										onChange={(value) =>
											handleChange(
												index,
												'type',
												value as string
											)
										}
										options={types}
										disabled={disabled}
										errorMessages={
											errors?.[
												`training_modules.${index}.type`
											] || null
										}
									/>
								</div>
							</div>
							{item.type === 'pdf' && (
								<FormInput
									type="file"
									label={StringHelpers.title(
										fields?.file || 'File'
									)}
									name="file"
									value={item.file}
									onChange={(value) =>
										handleChange(
											index,
											'file',
											value as string
										)
									}
									disabled={disabled}
									errorMessages={
										errors?.[
											`training_modules.${index}.file`
										] || null
									}
								/>
							)}
							{item.type === 'slideshow' && (
								<>
									<FormInput
										type="file"
										label={StringHelpers.title(
											fields?.file || 'File'
										)}
										name="file"
										value={item.file}
										onChange={(value) =>
											handleChange(
												index,
												'file',
												value as string
											)
										}
										disabled={disabled}
										errorMessages={
											errors?.[
												`training_modules.${index}.file`
											] || null
										}
									/>
									<div className="mb-4">OR</div>
									<FormInput
										type="text"
										label={StringHelpers.title(
											fields?.url || 'URL'
										)}
										name="external_url"
										value={item.external_url || ''}
										onChange={(value) =>
											handleChange(
												index,
												'external_url',
												value as string
											)
										}
										disabled={disabled}
										errorMessages={
											errors?.[
												`training_modules.${index}.external_url`
											] || null
										}
									/>
								</>
							)}
							{item.type === 'quiz' && (
								<FormInput
									type="searchable-select"
									name="training_quiz_id"
									placeholder="Search quizzes"
									value={item.training_quiz_id || ''}
									onChange={(value) =>
										handleChange(
											index,
											'training_quiz_id',
											value as string
										)
									}
									options={quizzes}
									disabled={disabled}
									errorMessages={
										errors?.[
											`training_modules.${index}.training_quiz_id`
										] || null
									}
								/>
							)}
							{item.type === 'youtube' && (
								<FormInput
									type="text"
									label={StringHelpers.title(
										fields?.youtube || 'YouTube'
									)}
									name="external_url"
									value={item.external_url || ''}
									onChange={(value) =>
										handleChange(
											index,
											'external_url',
											value as string
										)
									}
									disabled={disabled}
									errorMessages={
										errors?.[
											`training_modules.${index}.external_url`
										] || null
									}
								/>
							)}
							<FormInput
								type="textarea"
								label={StringHelpers.title(
									fields?.description || 'Description'
								)}
								name="description"
								value={item.description || ''}
								onChange={(value) =>
									handleChange(
										index,
										'description',
										value as string
									)
								}
								disabled={disabled}
								errorMessages={
									errors?.[
										`training_modules.${index}.description`
									] || null
								}
								noMargin
							/>
						</CardBody>
					</Card>
				))}
				<div className="d-flex justify-content-end">
					<Button
						label={StringHelpers.title(
							fields?.add_module || 'Add module'
						)}
						onClick={addItem}
						type={ButtonTypes.Outline}
						disabled={disabled}
						variant={Variants.Dark}
						size={Sizes.Small}
					/>
				</div>
			</CardBody>
		</Card>
	);
}

export default Modules;
