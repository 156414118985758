import { createContext, useContext } from 'react';
import { StageType } from '../../../../types/course';

interface StagesContextInterface {
	items: StageType[];
	setItems: (value: StageType[]) => void;
}

export const StagesContext = createContext<StagesContextInterface>({
	items: [],
	setItems: () => {},
});

export const useStagesContext = () => useContext(StagesContext);
